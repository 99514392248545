@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

p {
  font-size: 1.6rem;
}

h1 {
  font-size: 6rem;
}

.app {
  width: 100%;
  height: 100%;
  padding: 0%;
  position: relative;
  background-color: rgba(0,0,0,0);
  color: #fff;
}

.app::before {
  content: '';
  background: url(./assets/nycsunset4k.jpg) no-repeat center center/cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.app .title {
  text-align: center;
  font-size: 2.5rem;
  padding: 0.5rem;
}

.app .search {
  text-align: center;
  padding: 1rem;
}

.app input {
  padding: .7rem 2.5rem;
  font-size: 1.2rem;
  text-align: left;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.25);
  color: #f8f8f8;
}

::placeholder {
  color: #f8f8f8;
}

.container {
  max-width: 700px;
  height: 700px; /*adjust height?*/
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.app .top {
  width: 100%;
  margin: 1rem auto;
}

.app .description {
  position: relative;
}

.app .bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255,255,255,0.2);
}

.bold {
  font-weight: 700;
}